import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box, Grid, Stack, Link, Divider, Typography } from '@mui/material';
import { PATH_PAGE } from '../routes/paths';
import {Baa }  from '../assets';
import useResponsive from '../hooks/useResponsive';


// ----------------------------------------------------------------------
const RootStyle = styled('div')(({ theme }) => ({
    position: 'relative',
    backgroundColor: theme.palette.background.default,
  }));
  
export default function Footer() {
  const isDesktop = useResponsive('up', 'md');
  return (
   
    <>
    <Divider sx={{mt:5}}/>

    
      <Box
        sx={{
            display: 'grid',
            columnGap: 0,
            rowGap: 0,
            mb:-100
            }}
            >
      <Stack direction="row" alignItems="center" justifyContent={isDesktop?'flex-end':'center'} sx={{p:3}}>
      <Typography  variant="body2" sx={{ fontSize:12, mr:2, textAlign:'center'}}>
      © 2024 EA Solutions, LLC. All rights reserved.
            </Typography> 
      {
        isDesktop?(
          <>
       <Link spacing="2" target="_blank" sx={{fontSize:12}} component={RouterLink} variant="subtitle2" to={PATH_PAGE.faqs}>
         Privacy Policy
        </Link>
        <Link sx={{ml:2, fontSize:12}} target="_blank" component={RouterLink} variant="subtitle2" to={PATH_PAGE.terms}>
          Terms Of Service
        </Link> 
        <Link sx={{ml:2, fontSize:12}} href="https://elliottpodcast.s3.amazonaws.com/BAA+Agreement+-+SFN+as+BAA+for+Client.pdf" target="_blank" rel="noopener" variant="subtitle2">
        Business Associate Agreement
          </Link>
        </>):''
      
      }
      </Stack>

                </Box>
   
         
   
                </>
  
  );
}
