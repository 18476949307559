import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Dialog, DialogContent, List, ListSubheader, ListItemAvatar, ListItemText,  Paper, Skeleton, Alert, Stack, ListItemButton, Box, Typography, Input, Slide, Button, InputAdornment, ClickAwayListener  } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import * as moment from 'moment';
import Avatar from '../../../components/Avatar';
import axios from '../../../utils/axios';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { IconButtonAnimate } from '../../../components/animate';
import cssStyles from '../../../utils/cssStyles';
import createAvatar from '../../../utils/createAvatar';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import useAuth from '../../../hooks/useAuth';
// ----------------------------------------------------------------------
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 70;
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 2),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 3),
  },
}));
InvoiceAddressListDialog.propTypes = {
  addressOptions: PropTypes.array,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  
};

export default function InvoiceAddressListDialog({ open,addressOptions,onClose }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const isMountedRef = useIsMountedRef();
  const handleSelect = (address) => {
      console.log(address.id)
      if(address.id)
      {
        onClose()
        navigate(`${PATH_DASHBOARD.manage.root}/${address.id}`);
        setTimeout(() => {
          setSearchResults([]);
          setSearchQuery('');
        }, 1000);
        
      }
     
  };

  const handleClose=() =>{
    onClose();
    setTimeout(() => {
      setSearchResults([]);
      setSearchQuery('');
    }, 1000);
   // setSearchResults([]);
  }
  const handleChangeSearch = async (value) => {
    try {
      const date = moment().format('YYYY-MM-DD');
      const time = moment().format('HH:mm');
      const local = `${date}T${time}:00.000Z`;
      setSearchQuery(value);
      console.log(value)
      if (value) {
        setLoading(true)
        const response = await axios.get('/api/clients/search_all', {
          params: { query: value ,owner: user?.id, lt: local},
        });

        if (isMountedRef.current) {
          setLoading(false)
          setSearchResults(response.data.results);
        }
      }
      else{
        setLoading(false)
        setSearchResults([]);
      }
    } catch (error) {
      setLoading(false)
      console.error(error);
    }
  };
  return (
    <Dialog fullWidth maxWidth="sm"
      keepMounted
    open={open} onClose={onClose}>
       <DialogContent>
   <Stack
    >
     
          <SearchbarStyle>
            <Input
              autoFocus
              fullWidth
              disableUnderline
              value={searchQuery}
              onChange={(e) => handleChangeSearch(e.target.value)}
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <Iconify
                    icon={'eva:search-fill'}
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="outlined" onClick={handleClose}>
              Close
            </Button>
          </SearchbarStyle>
         
      <Scrollbar sx={{mt:8, mb:0.5, p: 1.5, pt: 2, maxHeight: 80 * 6 }}>
      <List
            disablePadding
            subheader={
              <ListSubheader disableSticky  sx={{ py: 1, fontSize:17, px: 2.5, typography: 'body1' }}>
                {
                  searchResults.length>0?'Serach Results':'Search by entering a keyword'
                }
              </ListSubheader>
            }
          >
        {
        searchResults.length?(searchResults.map((address) => (

<ListItemButton
  key={address.id}
            
  onClick={() => handleSelect(address)}
sx={{
  py: 1,
  px: 2.5,
  mt: '3px',
    flexDirection: 'row',
    alignItems: 'flex-start',
}}
>
<ListItemAvatar

>

<Avatar
sx={{mt:1.1}}
          variant="rounded"
          alt={address.name}
          src={address.cover || ''}
          color={createAvatar(address.email).color}
          >
          {createAvatar(address.name).name }
          </Avatar>
          </ListItemAvatar>
<ListItemText
  primary=
    {
      <>
      <Typography
      variant="body1"
      sx={{
        fontSize: 15,
        mt: 0.5,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
      }}
    >
       {address.name}
    </Typography>
    <Typography
    
    variant="body2"
    sx={{
      fontSize: 14,
      mt: -0.2,
      display: 'flex',
      alignItems: 'center',
      color: 'text.secondary',
    }}
  >
    {address.email}
  </Typography>
  </>
  }
  secondary={
    <Typography
      variant="caption"
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'text.disabled',
      }}
    >
     
      
      <Iconify icon="eva:clipboard-outline" sx={{ mr: 0.4, width: 16, height: 16 }} />
       Notes {address.notes} 
       <Iconify icon="eva:file-outline" sx={{ mr: 0.5, ml:1, width: 16, height: 16 }} />
       Plans {address.plans} 
       <Iconify icon="eva:attach-outline" sx={{ mr: 0.5, ml:1, width: 16, height: 16 }} />
       Files {address.files} 
       <Iconify icon="eva:calendar-outline" sx={{ mr: 0.5, ml:1, width: 16, height: 16 }} />
       Upcoming {address.upcoming}  
    </Typography>
  }
/>
</ListItemButton>
        ))): (

    <Stack sx={{ height:'200',width: '100%', mt:1, mb:1, p: 1.5, pt: 0 }}>

      {
      loading?(
      <Box>
      <Stack spacing={1} direction="row" alignItems="left" sx={{ height:70, p:1 }}> 
      <Skeleton animation="wave" variant="rounded" width={50} height={40} />
      <Stack direction="column" alignItems="left" width='100%' height="100%">
      <Skeleton animation="wave" variant="text" height={12} width="30%" /> 
      <Skeleton variant="text" animation="wave" sx={{mt:0.3}} height={10} width="40%" /> 
      <Skeleton variant="text" animation="wave" sx={{mt:0.5}} height={9} width="20%" />
    </Stack>
    </Stack>
    <Stack spacing={1} direction="row" alignItems="left" sx={{mt:0.3, height:70, p:1 }}> 
      <Skeleton animation="wave" variant="rounded" width={50} height={40} />
      <Stack direction="column" alignItems="left" width='100%' height={100}>
      <Skeleton animation="wave" variant="text" height={12} width="30%" /> 
      <Skeleton variant="text" animation="wave" sx={{mt:0.3}} height={10} width="40%" /> 
      <Skeleton variant="text" animation="wave" sx={{mt:0.5}} height={9} width="20%" />
    </Stack>
    </Stack>
    </Box>  ):''
      }

    </Stack>
)
        }
        </List>
      </Scrollbar>
      </Stack>
      </DialogContent>
    </Dialog>
  );
}
