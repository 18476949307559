import { useState, useEffect } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Avatar, Typography, ListItemText, ListItemAvatar, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
// utils
import { fToNow } from '../../../utils/formatTime';
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
// import { _contacts } from '../../../_mock';
// components
import Iconify from '../../../components/Iconify';
import Scrollbar from '../../../components/Scrollbar';
import MenuPopover from '../../../components/MenuPopover';
import BadgeStatus from '../../../components/BadgeStatus';
import { IconButtonAnimate } from '../../../components/animate';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

const ITEM_HEIGHT = 64;

// ----------------------------------------------------------------------

export default function ContactsPopover() {
  const [open, setOpen] = useState(null);
  const [_contacts, setContacts] = useState([]);
  const navigate = useNavigate();
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };
  const handleNavigate =(id)=>{
    navigate(`${PATH_DASHBOARD.manage.root}/${id}`);
  }
  
  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const therapistId = localStorage.getItem("user")
      const response = await axios.get(`/api/clients/list?id=${therapistId}`);
      if(response.data.contacts.length > 0)
      {
        window.localStorage.setItem('client', response.data.contacts[0].id);
      }
      else{
        window.localStorage.setItem('client', 0);
      }
    
    
      setContacts(response.data.contacts);
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])
  return (
    <>
      <IconButtonAnimate
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Iconify icon={'eva:people-fill'} width={20} height={20} />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 320,
          '& .MuiMenuItem-root': {
            px: 1.5,
            height: ITEM_HEIGHT,
            borderRadius: 0.75,
          },
        }}
      >
        <Typography variant="h6" sx={{ p: 1.5 }}>
          Clients <Typography component="span">({_contacts.length})</Typography>
        </Typography>

        <Scrollbar sx={{ height: ITEM_HEIGHT * 6 }}>
          {
          _contacts.length?(
          _contacts.map((contact) => (
            <MenuItem onClick={()=>{handleNavigate(contact.id)}} key={contact.id}>
              <ListItemAvatar sx={{ position: 'relative' }}>
                <Avatar src={contact.avatar} />
                <BadgeStatus status={contact.status} sx={{ position: 'absolute', right: 1, bottom: 1 }} />
              </ListItemAvatar>

              <ListItemText
                primaryTypographyProps={{ typography: 'subtitle2', mb: 0.25 }}
                secondaryTypographyProps={{ typography: 'subtitle3' }}
                primary={contact.name}
                // secondary={contact.status === 'offline' && fToNow(contact.lastActivity)}
                secondary={contact.email}
              />
            </MenuItem>
          ))): <Typography textAlign='center' variant="h6" sx={{ p: 1.5 }}>
             No Clients Found
        </Typography>
          }
        </Scrollbar>
      </MenuPopover>
    </>
  );
}
