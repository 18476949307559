import PropTypes from 'prop-types';
import { capitalCase } from 'change-case';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Card, Stack, Link, Alert, Tooltip, Container, Typography } from '@mui/material';
// routes
import { PATH_AUTH } from '../../routes/paths';
// hooks
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
// components
import Page from '../../components/Page';
import Logo from '../../components/Logo';
import Image from '../../components/Image';
// import MainLogo from '../../assets/logo.png';
// sections
import { LoginForm } from '../../sections/auth/login';
import {LogoDark, LogoLight }  from '../../assets';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  padding: theme.spacing(3),
  justifyContent: 'space-between',
  [theme.breakpoints.up('md')]: {
    alignItems: 'flex-start',
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------



export default function Login() {
  const { method } = useAuth();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');
  const isDesktop = useResponsive('up', 'md');
  return (
    <Page title="Login">
      <RootStyle>
        <HeaderStyle>
        <Typography variant="body2" sx={{ mt: { md: -2 } }} />
          {smUp && (
             <Typography variant="body2" sx={{ mt: { md: -2 } }}>
             Don’t have an account? {''}
             <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
               Register Now
             </Link>
           </Typography>
             
          )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
           
            {
           isLight?(
            <Image
              sx={{width:'80%', px: 2.5}}
              alt="login"
              src={LogoDark}
              
            />):(
              <Image
              sx={{width:'80%', px: 2.5}}
              alt="login"
              src={LogoLight}
              
            />
            )
            }
             <Typography variant="h4" sx={{ px: 2.5, mt: 2, mb: 5 }}>
             Client Note Taking For The Solution Focused Practitioner.
            </Typography>
          </SectionStyle>
        )}

        <Container maxWidth="sm">
          <ContentStyle>
            <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
              <Box sx={{ flexGrow: 1 }}>
              { 
              !isDesktop?(
                  <Image
                    sx={{width:'64%',mb:0.5}}
                    alt="login"
                    src={isLight?LogoDark:LogoLight}
                  />
                  ):''
              }
                <Typography variant="h4" gutterBottom>
                  Sign in to your account
                </Typography>
                <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography>
               
              </Box>

              <Tooltip title={capitalCase(method)} placement="right">
                <>
                 
                </>
              </Tooltip>
            </Stack>

           

            <LoginForm />

            {!smUp && (
               <Typography variant="body2" align="center" sx={{ mt: 3 }}>
               Don’t have an account?{' '}
               <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                 Register Now
               </Link>
             </Typography>
               
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page>
  );
}
