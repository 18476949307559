// routes
import { PATH_DASHBOARD} from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  settings: getIcon('ic_analytics'),
};

const navConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: <Iconify icon={'mdi:desktop-mac-dashboard'} width={20} height={20} /> },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: <Iconify icon={'mdi:calendar-month'} width={20} height={20} /> },
      { title: 'Manage Clients', path: PATH_DASHBOARD.user.list ,icon: <Iconify icon={'mdi:users'} width={20} height={20} /> },
      { title: 'Progress Note', path: PATH_DASHBOARD.progress,icon: <Iconify icon={'mdi:note-plus'} width={20} height={20} /> },
      { title: 'Treatment Plan', path: PATH_DASHBOARD.treatment.root ,icon: <Iconify icon={'mdi:note-plus'} width={20} height={20} /> },
      { title: 'Add Appointment', path: PATH_DASHBOARD.appointment.root,icon: <Iconify icon={'mdi:calendar-plus'} width={20} height={20} /> },
     
      { title: 'Client', path:  PATH_DASHBOARD.user.newUser ,icon: <Iconify icon={'mdi:user-plus'} width={20} height={20} /> },
      { title: 'Intake Form', path:  'https://devbckt.s3.amazonaws.com/SFN-TherapyIntakeForm.docx' ,icon: <Iconify icon={'mdi:download'} width={20} height={20} /> },
    ],
  },

  

  
];

export default navConfig;
