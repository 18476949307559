import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';


// ----------------------------------------------------------------------

export default function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyBFov_7j4gq0GJAYJPmrre-7JDlTltspXE",
    authDomain: "solutions-focused-notes.firebaseapp.com",
    projectId: "solutions-focused-notes",
    storageBucket: "solutions-focused-notes.appspot.com",
    messagingSenderId: "245547475948",
    appId: "1:245547475948:web:15519c60a8cde5722d16c9",
    measurementId: "G-PRGXYVXTTD"
  };
  // Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
  return (
    <ThemeProvider>
      <ThemeColorPresets>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <MotionLazyContainer>
                <ProgressBarStyle />
                <ChartStyle />
                <Settings />
                <ScrollToTop />
                <Router />
              </MotionLazyContainer>
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemeColorPresets>
    </ThemeProvider>
  );
}
