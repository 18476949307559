import { useState } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, InputAdornment, ClickAwayListener } from '@mui/material';
import useToggle from '../../../hooks/useToggle';
// utils
import cssStyles from '../../../utils/cssStyles';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
import InvoiceAddressListDialog from './InvoiceAddressListDialog';
// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const SearchbarStyle = styled('div')(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  top: 0,
  left: 0,
  zIndex: 99,
  width: '100%',
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  height: APPBAR_MOBILE,
  padding: theme.spacing(0, 3),
  boxShadow: theme.customShadows.z8,
  [theme.breakpoints.up('md')]: {
    height: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

export default function Searchbar() {
  const [isOpen, setOpen] = useState(false);
  const [open, setOpenq] = useState(false);
  const { toggle: openFrom, onOpen: onOpenFrom, onClose: onCloseFrom } = useToggle();

  const { toggle: openTo, onOpen: onOpenTo, onClose: onCloseTo } = useToggle();

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handle = () => {
    setOpenq(true);
    setOpen(false);
  };

  const data=[
    {
      'id':1,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':2,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':3,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':4,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':5,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':6,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':7,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':8,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':9,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
    {
      'id':10,
      'title':"Sourabh",
      'company':"Test Company",
      'address':"Sujanpur",
    },
  ]

  return (<>
    <ClickAwayListener onClickAway={handleClose}>
      <div>
      <IconButtonAnimate onClick={onOpenFrom}>
            <Iconify icon={'eva:search-fill'} width={20} height={20} />
          </IconButtonAnimate>
     
      </div>
    </ClickAwayListener>
    <InvoiceAddressListDialog
            open={openFrom}
            onClose={onCloseFrom}
            addressOptions={data}
          />
    </>
  );
}
