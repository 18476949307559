import { PlanFreeIcon, PlanStarterIcon, PlanPremiumIcon } from '../assets';

// ----------------------------------------------------------------------

const LICENSES = ['Standard', 'Standard Plus', 'Extended'];

export const _homePlans = [...Array(3)].map((_, index) => ({
  license: LICENSES[index],
  commons: ['One end products', '12 months updates', '6 months of support'],
  options: [
    'JavaScript version',
    'TypeScript version',
    'Design Resources',
    'Commercial applications',
  ],
  icons: [
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_sketch.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_figma.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_js.svg',
    'https://minimal-assets-api.vercel.app/assets/images/home/ic_ts.svg',
  ],
}));

export const _pricingPlans = [
  {
    subscription: 'monthly',
    icon: <PlanFreeIcon />,
    price: 39.00,
    caption: '$39.00/month only',
    time: '/month',
    planId: 'price_1L8hhmBKat5d2W08kRFyfed0',
    lists: [
      { text: 'Online Scheduling & Calendar', isAvailable: true },
      { text: 'Secure and Free Text & Email Reminders', isAvailable: true },
      { text: 'SFBT Lockable Progress Notes Template', isAvailable: true },
      { text: 'SFBT Intake Form', isAvailable: true },
      { text: 'Upload and Store Documentation', isAvailable: true },
      { text: 'Unlimited Client and File Storage', isAvailable: true },
      { text: 'HIPAA Compliant', isAvailable: true },
      { text: 'Exceptional Customer Support', isAvailable: true },
    ],
    labelAction: 'join now',
  },
  {
    subscription: 'yearly',
    icon: <PlanStarterIcon />,
    price: 397.00,
    caption: '$397.00/year only',
    time: '/year',
    planId: 'price_1L8hj3BKat5d2W080Zwybp3A',
    lists: [
      { text: 'Online Scheduling & Calendar', isAvailable: true },
      { text: 'Secure and Free Text & Email Reminders', isAvailable: true },
      { text: 'SFBT Lockable Progress Notes Template', isAvailable: true },
      { text: 'SFBT Intake Form', isAvailable: true },
      { text: 'Upload and Store Documentation', isAvailable: true },
      { text: 'Unlimited Client and File Storage', isAvailable: true },
      { text: 'HIPAA Compliant', isAvailable: true },
      { text: 'Exceptional Customer Support', isAvailable: true },
    ],
    labelAction: 'join now',
  },
  {
    subscription: 'lifetime',
    icon: <PlanPremiumIcon />,
    price: 1497.00,
    caption: '$1497.00 only for lifetime',
    time: '/lifetime',
    planId: 'price_1L8hjoBKat5d2W08P6B0rAe0',
    lists: [
      { text: 'Online Scheduling & Calendar', isAvailable: true },
      { text: 'Secure and Free Text & Email Reminders', isAvailable: true },
      { text: 'SFBT Lockable Progress Notes Template', isAvailable: true },
      { text: 'SFBT Intake Form', isAvailable: true },
      { text: 'Upload and Store Documentation', isAvailable: true },
      { text: 'Unlimited Client and File Storage', isAvailable: true },
      { text: 'HIPAA Compliant', isAvailable: true },
      { text: 'Exceptional Customer Support', isAvailable: true },
    ],
    labelAction: 'join now',
  },
];
