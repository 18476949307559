import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';
import useAuth from '../hooks/useAuth';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  const { user } = useAuth();
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element:<GuestGuard><ResetPassword /></GuestGuard> },
      ],
    },

    

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element:  user?.subscription === "Unpaid"?<Pricing />:<Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'app', element:  user?.subscription === "Unpaid"?<Pricing />:<GeneralApp /> },
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/list" replace />, index: true },
            { path: 'new', element:  user?.subscription === "Unpaid"?<Pricing />:<UserCreate /> },
            { path: 'list', element:  user?.subscription === "Unpaid"?<Pricing />:<Clients /> },
            { path: ':name/edit', element:  user?.subscription === "Unpaid"?<Pricing />:<UserCreate /> },
            { path: 'account', element:  user?.subscription === "Unpaid"?<Pricing />:<UserAccount /> },
            { path: 'feedback', element:  user?.subscription === "Unpaid"?<Pricing />:<Feedback /> },
          ],
        },
        
        {
          path: 'manage',
          children: [
            { element: user?.subscription === "Unpaid"?<Pricing />:<Navigate to={`/dashboard/manage/${localStorage.getItem("client")}`} replace />, index: true },
            { path: 'label/:customLabel', element:  user?.subscription === "Unpaid"?<Pricing />:<Mail /> },
            { path: 'label/:customLabel/:mailId', element:  user?.subscription === "Unpaid"?<Pricing />:<Mail /> },
            { path: ':systemLabel', element:  user?.subscription === "Unpaid"?<Pricing />:<Mail /> },
            { path: ':systemLabel/:mailId', element: user?.subscription === "Unpaid"?<Pricing />: <Mail /> },
          ],
        },
       
        { path: 'calendar', element: user?.subscription !== "Unpaid"?<Calendar />:<Navigate to={`/dashboard/pricing/`} replace /> },
        { path: 'pricing', element: user?.subscription === "Unpaid"?<Pricing />:<Navigate to={`/dashboard/calendar/`} replace />},
        { path: 'summary', element: <Summary />},
       
        
        {
          path: 'progress',
          children: [
            { element:  user?.subscription === "Unpaid"?<Pricing />:<Progress />, index: true },
            { path: ':name/edit', element: user?.subscription === "Unpaid"?<Pricing />: <EditProgress /> },
          ],
        },
        {
          path: 'appointment',
          children: [
            { element:  user?.subscription === "Unpaid"?<Pricing />:<Navigate to="/dashboard/appointment" replace />, index: true },
            { path: 'new', element:  user?.subscription === "Unpaid"?<Pricing />:<Appointment /> },
            { path: ':name/edit', element: user?.subscription === "Unpaid"?<Pricing />: <Appointment /> },
            
          ],
        },

        {
          path: 'treatment',
          children: [
            { element: user?.subscription === "Unpaid"?<Pricing />:<Treatment />, index: true },
            { path: ':name/edit', element: user?.subscription === "Unpaid"?<Pricing />:<Treatment /> },
          ],
        },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
       
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: 'privacy', element: <Faqs /> },
        { path: 'terms', element: <Terms /> },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />, index: true ,
  
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication 
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Summary = Loadable(lazy(() => import('../pages/Summary')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
// Dashboard
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
const UserAccount = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));
const UserCreate = Loadable(lazy(() => import('../pages/dashboard/UserCreate')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
// Main
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
const Treatment = Loadable(lazy(() => import('../pages/dashboard/Treatment')));
const Progress = Loadable(lazy(() => import('../pages/dashboard/Progress')));
const EditProgress = Loadable(lazy(() => import('../pages/dashboard/EditProgress')));
const Appointment = Loadable(lazy(() => import('../pages/dashboard/Appointment')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const Terms = Loadable(lazy(() => import('../pages/Terms')));
const Clients = Loadable(lazy(() => import('../pages/dashboard/Clients')));
const Feedback = Loadable(lazy(() => import('../pages/dashboard/Feedback')));
